.flatpickr-day.selected {
    background-color: #5C93C0;
    border: 2px solid #5C93C0
  }

  .flatpickr-day.selected span {
    color: #fff;
  }

  .flatpickr-day.selected:hover {
    background-color: #5C93C0; 
    color: #fff;
    border: 2px solid #5C93C0
  }

  .flatpickr-input[disabled] {
    color: inherit;
    background-color: inherit;
}

  #dropdown-disabled {
    appearance: none;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
  }